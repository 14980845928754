import { useState } from 'react';
import { useWeb3React } from '@web3-react/core';
import { useWalletClient } from '../viem-client';

export type AddTokenParams = {
  symbol: string;
  decimals: number;
  address: string;
  chainId?: number;
  icon?: string;
};

export function useAddToken({
  address,
  icon,
  symbol,
  decimals,
  chainId
}: AddTokenParams) {
  const [isPending, setIsPending] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const { chainId: connectedChainId } = useWeb3React();
  const walletClient = useWalletClient();

  const addToken = async () => {
    setIsPending(true);
    setIsSuccess(false);
    try {
      if (!walletClient) {
        throw new Error('Wallet client is not defined');
      }
      if (!chainId) throw new Error('Invalid chain');
      if (connectedChainId !== chainId) {
        if (
          !('switchChain' in walletClient) ||
          typeof walletClient.switchChain !== 'function'
        ) {
          throw new Error('switchChain not supported');
        }

        await walletClient.switchChain({ id: chainId });
      }

      if (
        !('watchAsset' in walletClient) ||
        typeof walletClient.watchAsset !== 'function'
      ) {
        throw new Error('watchAsset not supported');
      }

      const wasAdded = await walletClient.watchAsset({
        type: 'ERC20',
        options: {
          address,
          symbol,
          decimals,
          image: icon
        }
      });

      if (!wasAdded) {
        throw new Error('Failed to add token');
      }

      setIsSuccess(true);
    } catch (error) {
      console.error(error);
      if (error instanceof Error) {
        setError(error.message);
      } else {
        setError('Failed to add token');
      }
    } finally {
      setIsPending(false);
    }
  };
  return { addToken, error, isPending, isSuccess };
}
