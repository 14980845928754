import React, { useEffect, useState, useCallback } from 'react';
import Grid from '@mui/material/Grid';
import makeStyles from '@mui/styles/makeStyles';
import { Header, Input, OxenButton } from 'components';
import { SWAP_TYPE, TYPE } from 'utils/constants';
import config from 'config';
import { withEthereum } from '../withEtherum';
import { common } from 'theme';
import theme, { OxenTheme } from 'theme/oxen';
import { SelectChangeEvent } from '@mui/material/Select';
import StyledLabel from '../label';
import StyledRadio from '../radio';
import { useAddToken } from '../../hooks/useWatchAsset';
import colors from '../../theme/colors';
import { isAddress } from '../../utils';
import Typography from '@mui/material/Typography';
import { useActiveWeb3React } from '../../hooks';

const options = [
  {
    value: SWAP_TYPE.OXEN_TO_aSESH,
    description: 'OXEN to SESH'
  },
  {
    value: SWAP_TYPE.WOXEN_TO_eSESH,
    description: 'WOXEN to SESH'
  }
];

const useStyles = makeStyles((theme: OxenTheme) => ({
  root: {
    [theme.breakpoints.up('md')]: {
      maxWidth: '520px'
    },
    ...common.section
  },
  button: {
    marginTop: '24px'
  },
  createAccount: {
    fontSize: '0.9rem',
    textAlign: 'right',
    cursor: 'pointer',
    display: 'inline-block',
    color: colors.oxenGreen,
    marginBottom: '8px'
  }
}));

type SwapSelectionProps = {
  swapType: string;
  onSwapTypeChanged: (...args: any[]) => any;
  onNext: (...args: any[]) => void;
  setManualEthAddress: (address: string) => void;
  loading: boolean;
  eth: any;
};

function SwapSelection(props: SwapSelectionProps) {
  const {
    swapType,
    onSwapTypeChanged: _onSwapTypeChanged,
    onNext: _onNext,
    setManualEthAddress,
    loading,
    eth
  } = props;
  const { chainId } = useActiveWeb3React();

  const [address, setAddress] = useState('');

  const { addToken: addTokenEth } = useAddToken({
    ...config.sesh,
    ...config.esesh,
    chainId: 1
  });

  const { addToken: addTokenArb } = useAddToken({
    ...config.sesh,
    ...config.asesh,
    chainId: 42161
  });

  const { addToken: addTokenSepolia } = useAddToken({
    ...config.sesh,
    ...config.eseshTest,
    chainId: 11155111
  });

  const handleAddToken = async () => {
    if ((chainId as number) === 11155111 && config.eseshTest.address) {
      return await addTokenSepolia();
    }
    return await (swapType === SWAP_TYPE.OXEN_TO_aSESH
      ? addTokenArb
      : addTokenEth)();
  };

  const getAddressType = () => {
    return swapType === SWAP_TYPE.OXEN_TO_aSESH ? TYPE.aSESH : TYPE.eSESH;
  };

  const onAddressChanged = (event: any) => {
    setAddress(event.target.value);
    setManualEthAddress(event.target.value);
  };

  const onNext = () => {
    const isValidAddress = address && address.length > 0;
    if (isValidAddress) {
      _onNext(address);
    }
  };

  const updateAddress = useCallback(() => {
    setAddress(eth.account ?? '');
  }, [eth?.account]);

  const onSwapTypeChanged = (event: SelectChangeEvent<string>) => {
    _onSwapTypeChanged(event.target.value);
    updateAddress();
  };

  const addressType = getAddressType();
  const inputLabel = `${
    addressType === TYPE.aSESH ? 'Arbitrum' : 'Ethereum'
  } Destination Address`;

  const classes = useStyles(theme);

  const isAddressValid = !!isAddress(address);
  const showInputError = address.length > 0 && !isAddressValid;
  const disableButton = !isAddressValid || loading;

  useEffect(() => {
    updateAddress();
  }, [updateAddress]);

  return (
    <Grid item xs={12} className={classes.root}>
      <StyledLabel label='Connected Address' />
      <Header />
      <div style={{ marginBottom: '12px' }} />
      <StyledRadio
        label='Swap Type'
        options={options}
        value={swapType}
        handleChange={onSwapTypeChanged}
        disabled={loading}
      />
      <Input
        fullWidth
        label={inputLabel}
        placeholder='0x…'
        value={address}
        error={showInputError}
        onChange={onAddressChanged}
        disabled={
          !!(loading || swapType === SWAP_TYPE.WOXEN_TO_eSESH || eth.account)
        }
      />
      {showInputError ? (
        <Typography
          style={{
            color: 'hsl(0 100% 61)',
            marginTop: '-6px',
            marginBottom: '12px'
          }}
        >
          Invalid address
        </Typography>
      ) : null}
      {eth.account ? (
        <span className={classes.createAccount} onClick={handleAddToken}>
          Add SESH contract to wallet
        </span>
      ) : null}
      <OxenButton
        fullWidth
        label='Next'
        variant='outlined'
        loading={loading}
        disabled={disableButton}
        disabledLabel={
          swapType === SWAP_TYPE.WOXEN_TO_eSESH
            ? 'Connect wallet to continue'
            : 'Enter address to continue'
        }
        onClick={onNext}
      />
    </Grid>
  );
}

const EthSwapSelection = withEthereum(SwapSelection);
export default EthSwapSelection;
