import {type Abi, type Address, createPublicClient, createWalletClient, custom, http} from 'viem';
import {mainnet, sepolia} from 'viem/chains'
import {useActiveWeb3React} from "./hooks";

const testnetClient = createPublicClient({
  chain: sepolia,
  transport: http('/rpc/sepolia'),
})

const mainnetClient = createPublicClient({
  chain: mainnet,
  transport: http('/rpc/mainnet'),
})

const testnetWalletClient = createWalletClient({
  chain: sepolia,
  transport: custom(window.ethereum as unknown as any)
})

const mainnetWalletClient = createWalletClient({
  chain: mainnet,
  transport: custom(window.ethereum as unknown as any)
})

export const viemClient = {
  [sepolia.id]: {
    public: testnetClient,
    wallet: testnetWalletClient,
  },
  [mainnet.id]: {
    public: mainnetClient,
    wallet: mainnetWalletClient,
  }
}

const allowedChainIds = Object.keys(viemClient).map(Number)
export const isAllowedChainId = (chainId: number): chainId is keyof typeof viemClient => allowedChainIds.includes(chainId)

export type PublicViemClient = typeof mainnetClient | typeof testnetClient;
export type WalletViemClient = typeof mainnetWalletClient | typeof testnetWalletClient;
export type ViemContract = {
  address: Address | undefined | null,
  abi: Abi
}

export function usePublicClient() {
  const { chainId } = useActiveWeb3React();

  if (!chainId || !isAllowedChainId(chainId)) {
    return null;
  }

  return viemClient[chainId].public;
}

export function useWalletClient() {
  const { chainId } = useActiveWeb3React();

  if (!chainId || !isAllowedChainId(chainId)) {
    return null;
  }

  return viemClient[chainId].wallet;
}
