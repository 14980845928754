import { useTokenConverterContract} from '../hooks/useContract';
import {type PublicViemClient, usePublicClient, useWalletClient, type ViemContract} from "../viem-client";
import {useEffect, useState} from "react";
import {stringToBigInt} from "../utils/bigint";
import {useERC20Approve} from "./Approve";
import {erc20Abi} from "viem";

async function getInfo(viemClient: PublicViemClient, contract: ViemContract){
 if (!('multicall' in viemClient) || (typeof viemClient.multicall !== 'function')) {
    throw new Error('multicall not supported')
  }

  const res = await viemClient.multicall({
    contracts: [
      {
        ...contract,
        functionName: 'conversionRateNumerator',
      },
      {
        ...contract,
        functionName: 'conversionRateDenominator',
      },
      {
        ...contract,
        functionName: 'tokenA',
      },
      {
        ...contract,
        functionName: 'tokenB',
      },
    ]
  })

  return {
    numerator: res[0].result,
    denominator: res[1].result,
    tokenA: res[2].result,
    tokenB: res[3].result,
  }
}

export function useTokenConverterInfo() {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [result, setResult] = useState<null | Awaited<ReturnType<typeof getInfo>>>(null);
  const contract = useTokenConverterContract();
  const publicClient = usePublicClient();

  useEffect(() => {
    if (!result && publicClient && contract && !isLoading) {
      setIsLoading(true)
      getInfo(publicClient, contract).then(setResult)
    }
  }, [result, publicClient, contract, isLoading])

  return {result, isLoading}
}

export function useTokenConverterConvert() {
  const [isPending, setIsPending] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);

  const contract = useTokenConverterContract();
  const walletClient = useWalletClient();
  const publicClient = usePublicClient();

  const resolveToPending = () => {
    setIsPending(true);
    setError(null);
    setIsSuccess(false);
  }

  const resolveToSuccess = () => {
    setIsPending(false);
    setIsSuccess(true);
  }

  const resolveToError= (error: Error) => {
    setIsPending(false);
    setError(error);
  }

  const convert = async (amount: string) => {
    resolveToPending();
    if (!contract || !walletClient || !publicClient) {
      resolveToError(new Error('Web3 not initialized'));
      return
    }

    if (!('getAddresses' in walletClient) || (typeof walletClient.getAddresses !== 'function')) {
      resolveToError(new Error('getAddress not supported'));
      return;
    }

    const [address] = await walletClient.getAddresses()

    if (!('simulateContract' in publicClient) || (typeof publicClient.simulateContract !== 'function')) {
      resolveToError(new Error('simulateContract not supported'));
      return;
    }

    try {

      const atomicAmount = stringToBigInt(amount, 9);

      const { request } = await publicClient.simulateContract({
        account: address,
        address: contract.address,
        abi: contract.abi,
        functionName: 'convertTokens',
        args: [atomicAmount]
      })

      if (!('writeContract' in walletClient) || (typeof walletClient.writeContract !== 'function')) {
        resolveToError(new Error('writeContract not supported'));
        return;
      }

      await walletClient.writeContract(request)

      resolveToSuccess();
    } catch (e) {
      resolveToError(e as Error);
    }
  }

  return { convert, isPending, error, isSuccess }
}



export function useAddTokenB() {
  const [isPending, setIsPending] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);

  const approveHook = useERC20Approve();
  const contract = useTokenConverterContract();
  const walletClient = useWalletClient();
  const publicClient = usePublicClient();

  const resolveToPending = () => {
    setIsPending(true);
    setError(null);
    setIsSuccess(false);
  }

  const resolveToSuccess = () => {
    setIsPending(false);
    setIsSuccess(true);
  }

  const resolveToError= (error: Error) => {
    setIsPending(false);
    setError(error);
  }

  const add = async (amount: string) => {
    resolveToPending();
    if (!contract || !walletClient || !publicClient) {
      resolveToError(new Error('Web3 not initialized'));
      return
    }

    if (!contract.address) {
      resolveToError(new Error('Contract address not found'));
      return;
    }

    if (!('getAddresses' in walletClient) || (typeof walletClient.getAddresses !== 'function')) {
      resolveToError(new Error('getAddress not supported'));
      return;
    }

    const [address] = await walletClient.getAddresses()

    if (!('simulateContract' in publicClient) || (typeof publicClient.simulateContract !== 'function')) {
      resolveToError(new Error('simulateContract not supported'));
      return;
    }
    if (!('readContract' in publicClient) || (typeof publicClient.readContract !== 'function')) {
      resolveToError(new Error('readContract not supported'));
      return;
    }

    try {
      const tokenB = await publicClient.readContract({
        address: contract.address,
        abi: contract.abi,
        functionName: 'tokenB',
      })

      const tokenBAllowance = await publicClient.readContract({
        address: tokenB,
        abi: erc20Abi,
        functionName: 'allowance',
        args: [address, contract.address]
      })
      const atomicAmount = stringToBigInt(amount, 9);

      if (tokenBAllowance < atomicAmount) {
        await approveHook.approve({
          tokenContractAddress: tokenB,
          spender: contract.address,
          value: amount
        })
      }

      if (!('simulateContract' in publicClient) || (typeof publicClient.simulateContract !== 'function')) {
        return;
      }

      const { request } = await publicClient.simulateContract({
        account: address,
        address: contract.address,
        abi: contract.abi,
        functionName: 'depositTokenB',
        args: [atomicAmount]
      })

      if (!('writeContract' in walletClient) || (typeof walletClient.writeContract !== 'function')) {
        return;
      }

      await walletClient.writeContract(request)

      resolveToSuccess();
    } catch (e) {
      resolveToError(e as Error);
    }
  }

  return { add, isPending, error, isSuccess }


}
