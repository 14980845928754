import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import theme, { OxenTheme } from 'theme/oxen';

const useStyles = makeStyles((theme: OxenTheme) => ({
  inline: {
    verticalAlign: 'middle',
    display: 'inline-block',
    width: 'calc(100% - 50px)'
  }
}));

function Label(props: any) {
  const { label, style } = props;

  const classes = useStyles(theme);

  return (
    <Typography
      variant='h6'
      gutterBottom
      className={classes.inline}
      style={style}
    >
      {label}
    </Typography>
  );
}

Label.propTypes = {
  label: PropTypes.node.isRequired,
  style: PropTypes.object
};

Label.defaultProps = {
  style: {}
};

export default Label;
