import { Token, TokenAmount } from '@uniswap/sdk';
import {useMemo, useState} from 'react';

import { useTokenContract } from '../hooks/useContract';
import { useSingleCallResult } from '../state/multicall/hooks';
import {usePublicClient, useWalletClient} from "../viem-client";
import {type Address, erc20Abi} from "viem";

export function useTokenAllowance(
  token?: Token,
  owner?: string,
  spender?: string
): TokenAmount | undefined {
  const contract = useTokenContract(token?.address, false);

  const inputs = useMemo(() => [owner, spender], [owner, spender]);
  const allowance = useSingleCallResult(contract, 'allowance', inputs).result;

  return useMemo(
    () =>
      token && allowance
        ? new TokenAmount(token, allowance.toString())
        : undefined,
    [token, allowance]
  );
}


export function useERC20Allowance() {
  const [allowance, setAllowance] = useState<bigint | null>(null);
  const [isPending, setIsPending] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);

  const walletClient = useWalletClient();
  const publicClient = usePublicClient();

  const resolveToPending = () => {
    setIsPending(true);
    setError(null);
    setIsSuccess(false);
  }

  const resolveToSuccess = (res: bigint) => {
    setAllowance(res);
    setIsPending(false);
    setIsSuccess(true);
  }

  const resolveToError= (error: Error) => {
    setIsPending(false);
    setError(error);
  }

  const getAllowance = async ({token, spender}: {token: Address, spender: Address}) => {
    resolveToPending();
    if (!walletClient || !publicClient) {
      resolveToError(new Error('Web3 not initialized'));
      return
    }

    if (!token || !spender) {
      resolveToError(new Error('Token or spender not provided'));
      return
    }

    if (!('getAddresses' in walletClient) || (typeof walletClient.getAddresses !== 'function')) {
      resolveToError(new Error('getAddress not supported'));
      return;
    }

    const [address] = await walletClient.getAddresses()

    if (!('simulateContract' in publicClient) || (typeof publicClient.simulateContract !== 'function')) {
      resolveToError(new Error('simulateContract not supported'));
      return;
    }
    if (!('readContract' in publicClient) || (typeof publicClient.readContract !== 'function')) {
      resolveToError(new Error('readContract not supported'));
      return;
    }

    try {
      const res = await publicClient.readContract({
        address: token,
        abi: erc20Abi,
        functionName: 'allowance',
        args: [address, spender]
      })
      resolveToSuccess(res);
    } catch (e) {
      resolveToError(e as Error);
    }
  }

  return { allowance, getAllowance, isPending, error, isSuccess }
}
