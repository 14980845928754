/**
 * Converts a string to a BigInt.
 * @param value - The string to convert.
 * @param decimals - The number of decimals to round to.
 * @param decimalDelimiter - The decimal delimiter to use. Defaults to '.'.
 * @returns The BigInt representation of the string.
 */
export const stringToBigInt = (
  value: string,
  decimals: number,
  decimalDelimiter = '.'
): bigint => {
  if (!value.includes(decimalDelimiter)) {
    return BigInt(value) * BigInt(10) ** BigInt(decimals);
  }

  const [integer, fraction] = value.split(decimalDelimiter);

  if (integer === undefined || fraction === undefined) {
    throw new Error('Invalid string format');
  }

  return (
    BigInt(integer) * BigInt(10) ** BigInt(decimals) +
    BigInt(fraction.padEnd(decimals, '0'))
  );
};
