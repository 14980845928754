const config = {
  apiUrl: 'https://testnetbridge.oxen.io',
  useAPIEncryption: false,
  woxen: {
    txExplorerUrl: 'https://sepolia.arbiscan.io/tx'
  },
  oxen: {
    txExplorerUrl: 'https://oxen.observer/tx'
  },
  sesh: {
    symbol: "SESH",
    decimals: 9,

    name: 'Session Token',
    icon: 'https://stake.getsession.org/images/token_logo.svg',
  },
  asesh: {
    address: '0x7D7fD4E91834A96cD9Fb2369E7f4EB72383bbdEd',
  },
  esesh: {
    address: '0x0DBD22764C6C77827B4D03482998CA2dd61b5294',
  },
  eseshTest: {
    address: '0xcde850b83416c82e834ef5991fd8a7ef3df38de7'
  }
};

export default config;
