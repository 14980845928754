import React from 'react';
import clxs from 'clsx';
import makeStyles from '@mui/styles/makeStyles';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import colors from 'theme/colors';
import theme, { OxenTheme } from 'theme/oxen';

const useStyles = makeStyles((theme: OxenTheme) => ({
  root: {
    position: 'relative'
  },
  label: {
    'vertical-align': 'middle'
    //   "font-size": '0.825rem',
  },
  button: {
    minWidth: '120px',
    transition: 'all 0.2s ease-in-out',
    padding: '11px 16px',
    '&:hover': {
      backgroundColor: colors.chainflipMagenta,
      color: 'black',
      outline: '0'
    },
    color: 'black',
    'font-size': '12px'
  },
  textButton: {
    color: 'white',
    padding: '1.25rem 1rem'
  },
  secondary: {
    //border: `1px solid ${theme.palette.secondary.main}`,
    '&:hover': {
      backgroundColor: colors.lightBlack
    }
  },
  buttonProgress: {
    color: 'white',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  }
}));

type OxenButtonProps = {
  label: string;
  onClick?: (...args: any[]) => any;
  fullWidth?: boolean;
  disabled?: boolean;
  disabledLabel?: string;
  loading?: boolean;
  secondary?: boolean;
  variant?: 'text' | 'outlined' | 'contained';
  containerStyle?: React.CSSProperties;
};

export default function OxenButton(props: OxenButtonProps) {
  const {
    label,
    fullWidth,
    onClick,
    disabled,
    disabledLabel,
    secondary,
    loading,
    variant,
    containerStyle
  } = props;

  const classes = useStyles(theme);

  return (
    <div className={classes.root} style={containerStyle}>
      <Button
        className={clxs(
          'oxen-button',
          classes.button,
          secondary && classes.secondary,
          variant === 'text' && classes.textButton
        )}
        fullWidth={fullWidth}
        color={secondary ? 'secondary' : 'primary'}
        disabled={disabled || loading}
        onClick={onClick}
        variant={variant}
        style={{
          ...(disabled
            ? {
                backgroundColor: 'rgba(255, 255, 255, 0)'
              }
            : {})
        }}
      >
        {disabled ? disabledLabel || label : label}
      </Button>
      {loading && (
        <CircularProgress size={24} className={classes.buttonProgress} />
      )}
    </div>
  );
}
