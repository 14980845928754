import React from 'react';
import TimeAgo from 'timeago-react';
import dateformat from 'dateformat';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import config from 'config';
import { SWAP_TYPE, TYPE } from 'utils/constants';
import { green } from '@mui/material/colors';
import theme, { OxenTheme, colors } from 'theme/oxen';
import { collapseString } from '../../utils/collapseString';

const hashUrls = {
  [TYPE.aSESH]: config.oxen.txExplorerUrl,
  [TYPE.eSESH]: config.woxen.txExplorerUrl
};

const RenderHash = ({
  type,
  txHash,
  transferTxHashes,
  classes
}: {
  type: string;
  txHash: string;
  transferTxHashes: Array<string>;
  classes: any;
}) => {
  const depositHashType =
    type === SWAP_TYPE.OXEN_TO_aSESH ? TYPE.aSESH : TYPE.eSESH;
  const transferHashType =
    type === SWAP_TYPE.OXEN_TO_aSESH ? TYPE.eSESH : TYPE.aSESH;

  const swapHashes = transferTxHashes.map(hash => {
    const url = `${hashUrls[transferHashType]}/${hash}`;

    const link = (
      <a
        className={classes.hash}
        href={url}
        target='_blank'
        rel='noopener noreferrer'
      >
        {collapseString(hash, 10, 10)}
      </a>
    );

    if (transferTxHashes.length === 1) {
      return link;
    }

    return (
      <Typography key={hash} className={classes.hash}>
        {link}
      </Typography>
    );
  });

  const swapTitle =
    transferTxHashes.length === 1
      ? 'Swap Transaction: '
      : 'Swap Transactions: ';

  return (
    <>
      <Box>
        <Typography className={classes.hashTitle}>
          Deposit Transaction:{' '}
          <a
            className={classes.hash}
            href={`${hashUrls[depositHashType]}/${txHash}`}
            target='_blank'
            rel='noopener noreferrer'
          >
            {collapseString(txHash, 10, 10)}
          </a>
        </Typography>
      </Box>
      {transferTxHashes.length ? (
        <Typography className={classes.hashTitle}>
          {swapTitle}
          {transferTxHashes.length === 1 ? swapHashes[0] : null}
        </Typography>
      ) : null}
      {transferTxHashes.length > 1 ? swapHashes : null}
    </>
  );
};

const RenderTime = ({
  created,
  classes
}: {
  created: number;
  classes: any;
}) => {
  const timestamp = created * 1000 || Date.now();
  const now = Date.now();
  const diff = Math.abs(now - timestamp);
  const dayMs = 24 * 60 * 60 * 1000;

  const showFullDate = diff > dayMs;
  if (showFullDate) {
    const formatted = dateformat(timestamp, 'dd/mm/yyyy');
    return <Typography className={classes.time}>{formatted}</Typography>;
  }

  return <TimeAgo className={classes.time} datetime={timestamp} />;
};

const RenderSwapItem = ({
  uuid,
  type,
  amount,
  txHash,
  transferTxHashes,
  created,
  unconfirmed,
  oxenFee,
  classes
}: {
  uuid: number;
  type: string;
  amount: number; // TODO this might be a string
  txHash: string;
  transferTxHashes: Array<string>;
  created: number;
  unconfirmed: boolean;
  oxenFee: number;
  classes: any;
}) => {
  const isPending = transferTxHashes && transferTxHashes.length === 0;
  const depositCurrency = type === SWAP_TYPE.OXEN_TO_aSESH ? 'OXEN' : 'WOXEN';
  const fee = (oxenFee || 0) / 1e9;
  const displayAmount = amount / 1e9 - fee;
  const formattedAmount = displayAmount.toLocaleString('en-US', {
    maximumFractionDigits: 9,
    minimumFractionDigits: 0
  });

  let status = 'Completed';
  if (isPending) {
    status = unconfirmed ? 'Waiting for Confirmations' : 'Pending';
  }

  const feeStatus = fee > 0 ? `(${fee} OXEN Fee applied)` : '';

  return (
    <Grid item xs={12} key={uuid}>
      <Box className={classes.item}>
        <Box
          display='flex'
          flexDirection='row'
          alignItems='center'
          justifyContent='space-between'
          className={classes.xsColumn}
        >
          <Box display='flex' flexDirection='row' alignItems='center' gap={1}>
            <Typography className={classes.amount}>
              {formattedAmount} {depositCurrency}
            </Typography>
            {feeStatus ? <Typography>{feeStatus}</Typography> : null}
            <Typography className={classes.timeSeperator}>•</Typography>
            <Typography
              className={isPending ? classes.pending : classes.completed}
            >
              {status}
            </Typography>
          </Box>
        </Box>
        <RenderTime created={created} classes={classes} />
        <RenderHash
          type={type}
          txHash={txHash}
          transferTxHashes={transferTxHashes}
          classes={classes}
        />
      </Box>
    </Grid>
  );
};

const useStyles = makeStyles((theme: OxenTheme) => ({
  root: {
    margin: theme.spacing(2, 0)
  },
  item: {
    padding: theme.spacing(1, 2)
  },
  pending: {},
  completed: {
    color: green.A400
  },
  time: {
    color: colors.chainflipMagenta,
    fontSize: '0.8em'
  },
  timeSeperator: {
    fontWeight: 600,
    fontSize: '1.2em',
    lineHeight: '1em'
  },
  divider: {
    margin: '4px 0'
  },
  hashTitle: {
    fontSize: '0.9em'
  },
  hash: {
    fontStyle: 'italic',
    overflowWrap: 'break-word',
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  amount: {
    fontSize: '1em',
    fontWeight: 600,
    lineHeight: '1em'
  },
  emptyTitle: {
    color: '#c8c8c8',
    textAlign: 'center'
  },
  xsColumn: {
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column'
    }
  }
}));

type SwapListProps = {
  swaps: Array<any>;
};

export default function SwapList(props: SwapListProps) {
  const { swaps } = props;

  const classes = useStyles(theme);

  console.log(swaps);

  return (
    <Grid item xs={12}>
      <Grid
        container
        direction='column'
        spacing={1.5}
        minHeight={230}
        maxHeight={345}
        overflow='auto'
        wrap='nowrap'
        flexWrap='nowrap'
        paddingTop='8px'
      >
        {!swaps.length ? (
          <Box className={classes.item} style={{ margin: 'auto' }}>
            <Typography className={classes.emptyTitle}>
              No Transactions Found
            </Typography>
          </Box>
        ) : (
          swaps.map((swap, index) => {
            return <RenderSwapItem key={index} classes={classes} {...swap} />;
          })
        )}
      </Grid>
    </Grid>
  );
}
