import {useState} from "react";
import {usePublicClient, useWalletClient} from "../viem-client";
import {stringToBigInt} from "../utils/bigint";
import {type Address, erc20Abi, type Hash} from "viem";

export function useERC20Approve() {
  const [isPending, setIsPending] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);

  const walletClient = useWalletClient();
  const publicClient = usePublicClient();

  const resolveToPending = () => {
    setIsPending(true);
    setError(null);
    setIsSuccess(false);
  }

  const resolveToSuccess = () => {
    setIsPending(false);
    setIsSuccess(true);
  }

  const resolveToError= (error: Error) => {
    setIsPending(false);
    setError(error);
  }

  const approve = async ({
    tokenContractAddress,
    spender,
    value
    }: {
    tokenContractAddress: Address,
    spender: Address,
    value: string
  }) => {
    resolveToPending();
    if (!tokenContractAddress || !walletClient || !publicClient) {
      resolveToError(new Error('Web3 not initialized'));
      return
    }

    if (!('getAddresses' in walletClient) || (typeof walletClient.getAddresses !== 'function')) {
      resolveToError(new Error('getAddress not supported'));
      return;
    }

    const [address] = await walletClient.getAddresses()

    if (!('simulateContract' in publicClient) || (typeof publicClient.simulateContract !== 'function')) {
      resolveToError(new Error('simulateContract not supported'));
      return;
    }

    try {
      const atomicAmount = stringToBigInt(value, 9);

      const { request } = await publicClient.simulateContract({
        account: address,
        address: tokenContractAddress,
        abi: erc20Abi,
        functionName: 'approve',
        args: [spender, atomicAmount]
      })

      if (!('writeContract' in walletClient) || (typeof walletClient.writeContract !== 'function')) {
        resolveToError(new Error('writeContract not supported'));
        return;
      }

      const hash: Hash = await walletClient.writeContract(request)

      if (!('waitForTransactionReceipt' in publicClient) || (typeof publicClient.waitForTransactionReceipt !== 'function')) {
        resolveToError(new Error('waitForTransactionReceipt not supported'));
        return;
      }

      await publicClient.waitForTransactionReceipt({hash});

      resolveToSuccess();
    } catch (e) {
      resolveToError(e as Error);
    }
  }

  return { approve, isPending, error, isSuccess }
}
