import React, {
  useEffect,
  useState,
  useCallback,
  useRef,
  useMemo
} from 'react';
import { common } from 'theme';
import { OxenTheme, colors } from 'theme/oxen';
import makeStyles from '@mui/styles/makeStyles';
import AnimateHeight from 'react-animate-height';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { OxenButton, QRIcon, Input } from 'components';
import { SWAP_TYPE } from 'utils/constants';
import FileCopyOutlined from '@mui/icons-material/FileCopyOutlined';
import QRCode from 'qrcode.react';
import { withEthereum } from '../withEtherum';
import theme from 'theme/app';
import { NavLink } from 'react-router-dom';
import { useEthereumInfo } from '../../hooks/wOxen';
import { useTokenConverterConvert } from '../../data/TokenConverter';
import { useERC20Approve } from 'data/Approve';
import { useERC20Allowance } from '../../data/Allowances';
import { useTokenConverterContract } from '../../hooks/useContract';
import { stringToBigInt } from '../../utils/bigint';
import { formatNumber } from 'utils/formatNumber';

/**
 * Copies the specified text to the clipboard and displays a success toast message.
 *
 * @param textToCopy The text to be copied to the clipboard.
 */
async function copyToClipboard(
  textToCopy: string,
  onCopyComplete?: () => void
) {
  await navigator.clipboard.writeText(textToCopy);
  if (onCopyComplete) {
    onCopyComplete();
  }
}

const onCopy = (id: string) => {
  var elm = document.getElementById(id) as Node;
  let range;
  // for Internet Explorer
  const anyBody = document.body as any;

  if (anyBody.createTextRange) {
    range = anyBody.createTextRange();
    range.moveToElementText(elm);
    range.select();
    document.execCommand('Copy');
  } else if (window.getSelection) {
    // other browsers
    const selection = window.getSelection();
    if (selection) {
      range = document.createRange();
      range.selectNodeContents(elm);
      selection.removeAllRanges();
      selection.addRange(range);
      document.execCommand('Copy');
    }
  }
};

const RenderQR = (props: {
  showQR: boolean;
  qrSize: number;
  classes: any;
  swapInfo: any;
}) => {
  const { showQR, qrSize, classes, swapInfo } = props;
  const { depositAddress } = swapInfo;
  const height = showQR ? 'auto' : 0;

  return (
    <AnimateHeight duration={250} height={height}>
      <Box className={classes.qrContainer}>
        <Box className={classes.qr}>
          <QRCode value={depositAddress} renderAs='svg' size={qrSize} />
        </Box>
      </Box>
    </AnimateHeight>
  );
};

const RenderMemo = (props: { classes: any; swapInfo: any }) => {
  const {
    classes,
    swapInfo: { memo }
  } = props;

  if (!memo) return null;

  return (
    <Box className={classes.memoFrame}>
      <Typography className={classes.warningText}>
        PLEASE READ CAREFULLY
      </Typography>
      <Typography id='memo' className={classes.memo}>
        {memo}
      </Typography>
      <Tooltip title='Copy Memo' placement='right'>
        <IconButton
          onClick={() => onCopy('memo')}
          aria-label='Copy Memo'
          size='large'
        >
          <FileCopyOutlined />
        </IconButton>
      </Tooltip>
      <Typography className={classes.instructionBold}>
        When creating the transaction, please paste the string above into the{' '}
        <b>Memo</b> field. <br />
        Ensure that this is the only thing that you put in the field.
      </Typography>
      <Typography className={classes.warningText}>
        If done incorrectly then you will not receive <b>OXEN</b> into your
        designated address.
      </Typography>
    </Box>
  );
};
const maxNum = 100_000_000_000;

const RenderSwapInstructions = (props: {
  swapType: string;
  classes: any;
  swapInfo: any;
  info: any;
  loading: boolean;
  amount: string;
  setAmount: Function;
  conversionRatio: {
    numerator: number;
    denominator: number;
  };
}) => {
  const { swapInfo, amount, setAmount, classes, conversionRatio } = props;

  const { balance } = useEthereumInfo();
  const converterContract = useTokenConverterContract();
  const convertHook = useTokenConverterConvert();
  const allowanceHook = useERC20Allowance();
  const approveHook = useERC20Approve();

  // const addHook = useAddTokenB();
  const tokensDue = useMemo(() => {
    if (!swapInfo || !conversionRatio) return 0;

    return (
      (Number.parseFloat(amount) * conversionRatio.numerator) /
      conversionRatio.denominator
    );
  }, [swapInfo, amount, conversionRatio]);

  const amountBigInt = stringToBigInt(amount, 9);

  const onAmountChange = (event: any) => {
    let value = +Number(event.target.value).toFixed(9);
    // skip NaN check when containing a start/end with .
    if (event.target.value.match(/^\.|\.$/)) {
      value = event.target.value;
    } else {
      if (isNaN(value)) {
        return;
      }
    }
    if (value >= maxNum) {
      value = maxNum;
    }
    // allow numbers
    setAmount(
      '' + value // convert back to string because Input requires string
    );
  };

  const hasAllowance = !!(
    allowanceHook.allowance &&
    allowanceHook.allowance > 0n &&
    allowanceHook.allowance >= amountBigInt
  );

  console.log(convertHook);
  const handleClick = async () => {
    if (!swapInfo?.tokenA || !converterContract.address) return;

    if (!hasAllowance) {
      await approveHook.approve({
        tokenContractAddress: swapInfo?.tokenA,
        spender: converterContract.address,
        value: amount
      });
      if (approveHook.isSuccess) {
        await getTokenAllowance();
      }
    }

    await convertHook.convert(amount);
  };

  const handleMaxClick = () => {
    if (!balance) return;
    setAmount(balance.toString());
  };

  const getTokenAllowance = async () => {
    if (
      !allowanceHook.isPending &&
      converterContract.address &&
      swapInfo?.tokenA
    ) {
      await allowanceHook.getAllowance({
        token: swapInfo.tokenA,
        spender: converterContract.address
      });
    }
  };

  const userInteractionDisabled =
    approveHook.isPending || convertHook.isPending || convertHook.isSuccess;

  useEffect(() => {
    if (!allowanceHook.allowance) {
      void getTokenAllowance();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- on mount
  }, []);

  useEffect(() => {
    if (approveHook.isSuccess) {
      void getTokenAllowance();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- dont need the function
  }, [approveHook.isSuccess]);

  return (
    <React.Fragment>
      {/*<div>*/}
      {/*  <IconButton*/}
      {/*    onClick={()=> addHook.add('10000000000')}*/}
      {/*    aria-label='Set to the maximum amount'*/}
      {/*    size='small'*/}
      {/*  >*/}
      {/*    Add*/}
      {/*  </IconButton>*/}
      {/*</div>*/}
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          gap: '8px',
          alignItems: 'end'
        }}
      >
        <Input
          label='Enter the amount of WOXEN to swap:'
          value={amount}
          fullWidth
          onChange={onAmountChange}
          glassyVariant
          disabled={userInteractionDisabled}
          style={{
            fontSize: '14px'
          }}
          overrideEndAdornment={
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'row',
                marginLeft: '4px'
              }}
            >
              <IconButton
                onClick={handleMaxClick}
                disabled={userInteractionDisabled}
                aria-label='Set to the maximum amount'
                style={{
                  color: 'var(--session-green)'
                }}
                size='small'
              >
                Max
              </IconButton>
            </div>
          }
        />
        <OxenButton
          fullWidth
          label={hasAllowance ? 'Send' : 'Approve'}
          variant='outlined'
          onClick={handleClick}
          containerStyle={{ height: 'min-content', marginBottom: '12px' }}
          disabled={convertHook.isSuccess || amountBigInt === 0n}
          loading={approveHook.isPending || convertHook.isPending}
        />
      </div>
      <Typography className={classes.instructionBold}>
        SESH swap amount:{' '}
        <span style={{ color: 'var(--session-green)' }}>
          {tokensDue ? formatNumber(tokensDue) : 0} SESH{' '}
        </span>
      </Typography>
    </React.Fragment>
  );
};

const RenderBridgeDepositInstructions = (props: {
  swapType: string;
  classes: any;
  swapInfo: any;
  info: any;
  eth: any;
  loading: boolean;
  amount: string;
  setAmount: Function;
  minAmount: number;
  burn: Function;
  showQR: boolean;
  qrSize: number;
  toggleQR: any;
}) => {
  const {
    swapType,
    classes,
    swapInfo,
    eth,
    loading,
    amount,
    setAmount,
    minAmount,
    burn,
    showQR,
    qrSize,
    toggleQR
  } = props;

  const depAddressRef = useRef<HTMLInputElement>(null);

  const { depositAddress } = swapInfo;

  const onAmountChange = (event: any) => {
    let value = +Number(event.target.value).toFixed(9);
    // skip NaN check when containing a start/end with .
    if (event.target.value.match(/^\.|\.$/)) {
      value = event.target.value;
    } else {
      if (isNaN(value)) {
        return;
      }
    }
    // allow numbers
    setAmount(
      '' + value // convert back to string because Input requires string
    );
  };

  const onBurn = async () => {
    // warn user if amount is out of bounds
    if (isNaN(Number(amount))) {
      alert('Amount is invalid');
      return;
    }

    if (!eth.account) {
      alert(
        'Failed to get wallet information from metamask. Try reconnecting.'
      );
      return;
    }

    if (eth.balance === undefined) {
      alert('Failed to get wallet balance from metamask. Try reconnecting.');
      return;
    }

    if (!swapInfo.memo) {
      console.error('Cannot find burn memo', swapInfo);
      alert('Something went wrong');
      return;
    }

    const numberAmount = Number(amount);

    if (numberAmount <= minAmount) {
      alert('Amount must be more than ' + minAmount);
      return;
    }

    if (numberAmount > eth.balance) {
      alert('You do not have more than ' + eth.balance);
      return;
    }

    try {
      await burn(numberAmount, swapInfo.memo);
      console.log(`Burned ${numberAmount} wOxen`);
    } catch (e) {
      console.error('onBurn', e);
    }
  };

  if (swapType === SWAP_TYPE.WOXEN_TO_eSESH) {
    if (!eth.account) {
      return (
        <React.Fragment>
          <Typography className={classes.instructionBold}>
            Please connect your web3-enabled wallet
          </Typography>
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <Box className={classes.memoFrame}>
          <Input
            fullWidth
            value={amount}
            onChange={onAmountChange}
            label='How much wOxen do you want to transfer? '
            // TODO doesn't exist on component
            // loading={loading}
          />
          <OxenButton
            fullWidth
            label='Send'
            loading={loading}
            variant='outlined'
            onClick={async () => {
              await onBurn();
            }}
          />
        </Box>
        <Typography className={classes.instructionBold}>
          After you click Send look for a MetaMask popup to confirm the (gas
          fees for the) Send transaction.
        </Typography>
      </React.Fragment>
    );
  }

  const handleClick = async () => {
    if (depositAddress) {
      await copyToClipboard(depositAddress, () => {
        console.log('copied');
        console.log(depAddressRef.current);
        depAddressRef.current?.select();
      });
    }
  };

  return (
    <React.Fragment>
      <Input
        label='Transfer your OXEN to:'
        fullWidth
        value={depositAddress}
        multiline
        glassyVariant
        ref={depAddressRef}
        overrideEndAdornment={
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'row',
              marginLeft: '4px'
            }}
          >
            <Tooltip title='Copy Address' placement='left'>
              <IconButton
                onClick={handleClick}
                aria-label='Copy Address'
                size='small'
              >
                <FileCopyOutlined />
              </IconButton>
            </Tooltip>
            <Tooltip title='Toggle QR' placement='right'>
              <IconButton
                onClick={toggleQR}
                aria-label='Toggle QR'
                size='small'
              >
                <QRIcon />
              </IconButton>
            </Tooltip>
          </div>
        }
      />
      <RenderQR
        showQR={showQR}
        qrSize={qrSize}
        classes={classes}
        swapInfo={swapInfo}
      />
      <RenderMemo classes={classes} swapInfo={swapInfo} />
    </React.Fragment>
  );
};

function BridgeInfoCopy({
  classes,
  oxenFee
}: {
  classes: any;
  oxenFee: number;
}) {
  return (
    <>
      <Typography className={classes.instructions}>
        There will be a processing fee of {oxenFee} OXEN which will be charged
        when processing all your pending swaps.
      </Typography>
      <Typography className={classes.instructionBold}>
        When you swap OXEN via the bridge, you will be sent SESH on the Arbitrum
        blockchain.
      </Typography>
      <Typography className={classes.instructions}>
        Large transactions may take up to 24 hours to process. If you run into
        any trouble, or your swap request has not gone through 1 hour after
        sending, please contact us:{' '}
        <a href='mailto:contact@session.foundation'>
          contact@session.foundation
        </a>
      </Typography>
    </>
  );
}

function SwapInfoCopy({ classes }: { classes: any }) {
  return (
    <>
      <Typography className={classes.instructions}>
        To swap, first click the <strong>Approve</strong> button. This approves
        swap contract to swap your WOXEN for SESH using your connected wallet
      </Typography>
      <Typography className={classes.instructions}>
        Once approved, click the Send button to transfer your WOXEN to the
        contract for swapping
      </Typography>
      <Typography className={classes.instructions}>
        You will receive the <strong>SESH swap amount</strong> on the Ethereum
        blockchain once the transaction is successfully processed.
      </Typography>
      <Typography className={classes.instructions}>
        If you run into any trouble, or your swap has not been processed, please
        contact us:{' '}
        <a href='mailto:contact@session.foundation'>
          contact@session.foundation
        </a>
      </Typography>
    </>
  );
}

const RenderInstructions = (props: {
  swapType: string;
  classes: any;
  info: any;
  swapInfo: any;
  bridgeInfo: any;
  eth: any;
  loading: boolean;
  amount: string;
  setAmount: Function;
  minAmount: number;
  burn: Function;
  showQR: boolean;
  qrSize: number;
  toggleQR: Function;
}) => {
  const {
    swapType,
    classes,
    info,
    swapInfo,
    eth,
    loading,
    amount,
    setAmount,
    minAmount,
    burn,
    showQR,
    qrSize,
    toggleQR
  } = props;

  const oxenFee = (info && info.fees && (info.fees['oxen'] || 0) / 1e9) || 0;

  const depositCurrency =
    swapType === SWAP_TYPE.OXEN_TO_aSESH ? 'OXEN' : 'WOXEN';

  const conversionRatio = useMemo(() => {
    if (swapType === SWAP_TYPE.WOXEN_TO_eSESH) {
      if (!swapInfo || !swapInfo.denominator) {
        return { numerator: 0, denominator: 1 };
      }
      return {
        numerator: Number.parseInt(swapInfo.numerator),
        denominator: Number.parseInt(swapInfo.denominator)
      };
    }
    if (!info || !info.conversion || !info.conversion.denominator) {
      return { numerator: 0, denominator: 1 };
    }
    return {
      numerator: info.conversion.numerator,
      denominator: info.conversion.denominator
    };
  }, [info, swapInfo, swapType]);

  const calculatedConversionRatio = useMemo(() => {
    if (!swapInfo || !conversionRatio) return 0;
    return conversionRatio.numerator / conversionRatio.denominator;
  }, [swapInfo, conversionRatio]);

  return (
    <Box className={classes.instructionContainer}>
      <Typography
        className={classes.instructionBold}
        style={{ fontSize: '16px', marginBottom: '0px' }}
      >
        Here's what you need to do next:
      </Typography>
      {swapType === SWAP_TYPE.WOXEN_TO_eSESH ? (
        <RenderSwapInstructions
          swapType={swapType}
          classes={classes}
          swapInfo={swapInfo}
          info={info}
          loading={loading}
          amount={amount}
          setAmount={setAmount}
          conversionRatio={conversionRatio}
        />
      ) : (
        <RenderBridgeDepositInstructions
          swapType={swapType}
          classes={classes}
          swapInfo={swapInfo}
          info={info}
          eth={eth}
          loading={loading}
          amount={amount}
          setAmount={setAmount}
          minAmount={minAmount}
          burn={burn}
          showQR={showQR}
          qrSize={qrSize}
          toggleQR={toggleQR}
        />
      )}
      {calculatedConversionRatio !== null ? (
        <Typography className={classes.instructionBold}>
          Conversion rate:{' '}
          <NavLink to='/tos'>
            {' '}
            1 {depositCurrency} = {calculatedConversionRatio} SESH
          </NavLink>
        </Typography>
      ) : null}
      <Typography className={classes.instructions}>
        <NavLink to='/tos'>Learn more</NavLink> about the conversion rate
        between {depositCurrency} and SESH
      </Typography>
      {swapType === SWAP_TYPE.WOXEN_TO_eSESH ? (
        <SwapInfoCopy classes={classes} />
      ) : (
        <BridgeInfoCopy classes={classes} oxenFee={oxenFee} />
      )}
    </Box>
  );
};

const useStyles = makeStyles((theme: OxenTheme) => ({
  root: {
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: '700px',
      position: 'sticky',
      top: 0
    },
    ...common.section
  },
  rootNoBackground: {
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: '500px',
      position: 'sticky',
      top: 0
    }
  },
  instructionContainer: {
    marginTop: '16px',
    flexDirection: 'column',
    wordBreak: 'break-word'
  },
  instructions: {
    fontSize: '0.9rem',
    textAlign: 'left',
    marginBottom: '16px'
  },
  instructionBold: {
    color: colors.white,
    fontSize: '0.9rem',
    fontWeight: 'bold',
    textAlign: 'left',
    marginBottom: '16px',
    overflowWrap: 'break-word'
  },
  memoFrame: {
    marginBottom: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  memo: {
    border: '1px solid',
    borderColor: colors.oxenBlack90,
    borderRadius: '3px',
    backgroundColor: colors.oxenBlack90,
    color: 'white',
    padding: '1rem',
    overflowWrap: 'break-word',
    maxWidth: '100%',
    textAlign: 'left'
  },
  warningText: {
    color: theme.palette.text.secondary,
    margin: theme.spacing(1, 0),
    textAlign: 'left'
  },
  link: {
    cursor: 'pointer',
    a: {
      color: colors.oxenBlack90,
      fontWeight: 500
    }
  },
  qr: {
    padding: theme.spacing(1),
    backgroundColor: 'rgba(0,0,0,0)',
    '& svg > path:first-child': {
      fill: 'none'
    },
    '& svg > path': {
      fill: 'white'
    }
  },
  qrContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: theme.spacing(2)
  }
}));

type SwapInfoProps = {
  swapType: string;
  swapInfo: any;
  bridgeInfo: any;
  info: any;
  onRefresh: (...args: any[]) => any;
  onBack: (...args: any[]) => any;
  loading: boolean;
  eth: any;
  burn: Function;
};

let timer: any;

function SwapInfo(props: SwapInfoProps) {
  const {
    swapType,
    swapInfo,
    bridgeInfo,
    info,
    onRefresh,
    onBack,
    loading,
    eth,
    burn
  } = props;
  const [showQR, setShowQR] = useState(false);
  const [qrSize, setQRSize] = useState(128);
  const [amount, setAmount] = useState('0');
  const [minAmount, setMinAmount] = useState(0);

  const classes = useStyles(theme);

  const updateFee = useCallback(() => {
    const oxenFee = (info && info.fees && info.fees.oxen / 1e9) || 0;
    setMinAmount(oxenFee);
  }, [info]);

  const onResize = () => {
    const width = window.innerWidth;
    const newSize = width <= 600 ? 128 : 210;
    setQRSize(newSize);
  };

  const toggleQR = () => {
    setShowQR(!showQR);
  };

  useEffect(() => {
    updateFee();

    // Run a timer every 10 seconds to refresh
    timer = setInterval(onRefresh, 10 * 1000);

    onResize();
    window.addEventListener('resize', onResize);

    return () => {
      clearInterval(timer);
      window.removeEventListener('resize', onResize);
    };
  }, [onRefresh, updateFee]);

  useEffect(() => {
    // TODO need to check if info is different
    // if (!isEqual(prevProps.info, this.props.info)) {
    //   this.updateFee();
    // }
  }, []);

  return (
    <div className={classes.rootNoBackground}>
      {/* TODO Confirm we want left alignment but relative to what? */}
      <Grid item xs={12} alignItems='flex-start'>
        <Typography>
          <Link className={classes.link} onClick={onBack}>
            &lt; Back
          </Link>
        </Typography>
      </Grid>
      <RenderInstructions
        swapType={swapType}
        classes={classes}
        swapInfo={swapInfo}
        bridgeInfo={bridgeInfo}
        info={info}
        eth={eth}
        loading={loading}
        amount={amount}
        setAmount={setAmount}
        minAmount={minAmount}
        burn={burn}
        showQR={showQR}
        qrSize={qrSize}
        toggleQR={toggleQR}
      />
    </div>
  );
}

const EthSwapInfo = withEthereum(SwapInfo);
export default EthSwapInfo;
