import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const svg = (
  <svg
    width='24'
    height='24'
    viewBox='0 0 16 12'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      id='refresh'
      d='M7.89674 12.0042C9.17477 12.0042 10.4089 11.575 11.287 10.8709C11.8174 10.4652 11.9299 9.89825 11.5856 9.49176C11.2288 9.07959 10.7253 9.07643 10.2528 9.40586C9.53912 9.95537 8.82919 10.2383 7.89674 10.2383C5.94671 10.2383 4.33143 8.94704 3.86999 7.22002H4.87367C5.38291 7.22002 5.52207 6.71374 5.22621 6.31898L3.53797 3.99777C3.24106 3.59702 2.73753 3.571 2.43194 3.99777L0.764856 6.31898C0.468998 6.72249 0.599481 7.22002 1.10872 7.22002H2.142C2.65251 10.0387 4.97265 12.0042 7.89674 12.0042ZM7.87942 0.44873C6.61204 0.44873 5.36727 0.869152 4.49978 1.57338C3.96749 1.97899 3.85688 2.54596 4.20117 2.95243C4.55793 3.36459 5.06146 3.36586 5.52333 3.03828C6.23893 2.49753 6.94887 2.2059 7.87942 2.2059C9.83138 2.2059 11.4447 3.49719 11.9168 5.22412H10.8592C10.3413 5.22412 10.2089 5.73041 10.4979 6.12526L12.1949 8.44644C12.4917 8.84716 12.9953 8.87323 13.3008 8.44644L14.9679 6.12526C15.2638 5.73041 15.1333 5.22412 14.6154 5.22412H13.6342C13.1324 2.40364 10.8141 0.44873 7.87942 0.44873Z'
      fill='white'
    />
  </svg>
);

const RefreshIcon = (props: any) => {
  return <SvgIcon {...props}>{svg}</SvgIcon>;
};

export default RefreshIcon;
